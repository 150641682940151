<template>
   <div class="px-1 pb-2 pt-0">
        <v-card rounded="lg">
            <v-card-title class="" style="border-bottom:1px solid #ddd">
                <h5 class="pb-0"><b>{{$t('sending_programed')}}</b></h5>
            </v-card-title>
            <v-card-text class="px-0 pb-1">
                <v-simple-table class="retroplanningTable" v-if="retroplannings.data && retroplannings.data.length > 0">
                    <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">
                                {{ $t('operation') }}
                            </th>
                            <th class="text-left">
                                {{ $t('campaign') }}
                            </th>
                            <th class="text-left">
                                {{ $t('date_launch') }}
                            </th>
                            <th class="text-left">
                                {{ $t('type') }}
                            </th>
                            <th class="text-left">
                                {{ $t('estimatedsend') }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                        v-for="item in retroplannings.data"
                        :key="item.id"
                        >
                        <td>{{ item.operation_id.label }}</td>
                        <td>{{ item.name }}</td>
                        <td>{{ (item.date_launch) ? parseFullDateToFr(item.date_launch) : "" }}</td>
                        <td>{{ $t(item.sousType) }}</td>
                        <td>{{ item.config && item.config.contact_number ? (item.config.prevision_limit && item.config.contact_number > item.config.sending_vol ? item.config.sending_vol : item.config.contact_number) : "" }}</td>
                        </tr>
                    </tbody>
                    </template>
                </v-simple-table>
                <v-alert  v-else class="mt-4 mx-4 mb-4" border="left" color="info" text>
                    {{$t('emptyRetroplanningSent')}}
                </v-alert>
            </v-card-text>
        </v-card>        
    </div>
</template>
<script>
import dateManipulation from '@/mixins/dateManipulation'
export default {
    name: "marketingRetroplanning",
    props:["retroplannings"],
    mixins:[dateManipulation],
    data() {
        return {
            
        }
    },
}
</script>
<style lang="scss">
    .retroplanningTable {
        th{
            font-size:13px;
        }
        td {
            font-size:13px !important;
        }
    }
</style>