<template>
    <div>
        <div class="d-flex align-center">
            <v-chip small link dark @click="dialogFilter = true" color="purple" class="mr-2" >
                {{$t('filters')}}
            </v-chip>

            <div class="d-flex align-center">
                <div v-for="(filter, index) in filters" v-show="filter.value && filter.value.length" :key="'selectedFilter_'+filter.name">
                    <v-chip outlined small v-if="filter.name == 'month'" class="mr-2">
                        {{ filter.value == 'this month' ? $t('thismonth') : getMonthYear(filter.value) }}
                        <v-icon small @click="removeFilter(filter, filter.value)" class="ml-2">
                            $close
                        </v-icon>
                    </v-chip>
                    <span v-else>
                        <v-chip small outlined v-for="(val, index) in filter.value"  :key="'selectedFilter_'+filter.name+'_'+(val.text ? val.text : val)" class="mr-2" >
                            {{ val.text }}
                            <v-icon small @click="removeFilter(filter, val)" class="ml-2">
                                $close
                            </v-icon>
                        </v-chip>
                    </span>
                </div>
            </div>
        </div>
        
        <v-dialog v-model="dialogFilter" width="600">
            <v-card>
                <v-toolbar tile flat dark  class="bg-gradient" height="64" style="height:80px;">
                    <div style="display:flex; width:100%; height:64px; align-items:center; justify-content:space-between;">
                        <div class="d-flex align-center">
                            <v-toolbar-title class="d-flex align-center">{{$t("homeFilters")}}</v-toolbar-title>
                        </div>
                        <div>
                            <v-btn outlined dark depressed @click.native="dialogFilter = false">{{  $t('cancel') }}</v-btn>
                            <v-btn outlined dark depressed @click="saveAndFilter" class="ml-3">{{  $t('saveandfilter') }}</v-btn>
                            <v-btn outlined dark depressed  @click="filterHome" class="ml-3">{{ $t('filter') }}</v-btn>
                        </div>
                    </div> 
                </v-toolbar>
                <v-card-text class="pb-6">
                    <v-row class="pt-4">
                        <v-col cols="12" v-for="(filter, index) in filters" :key="'filter_'+filter.name">
                            <div v-if="filter.type == 'select'">
                                <VCustomSelect :model="filterSelected" :fieldName="filter.name" :field="filter" />
                            </div>
                            <div v-if="filter.type == 'month'">
                                <vMonthPicker :model="filterSelected" :fieldName="filter.name" :field="filter" />
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import VCustomSelect from "@/components/forms/inputs/v-custom-select";
import vMonthPicker from '@/components/forms/inputs/v-month-picker'
import dateManipulation from '@/mixins/dateManipulation'
import GenericDataService from '@/services/GenericDataService';

export default {
    name: 'homeFiltersComponent',
    components: {VCustomSelect, vMonthPicker},
    props: ['filters'],
    mixins: [dateManipulation],
    data() {
        return {
            dialogFilter : false,
            filterSelected: {}
        }
    },
    created() {
        if (this.$route.query.homeFilters) {
            let filter = this.$route.query.homeFilters.replace("%7B","{");
            filter = filter.replace("%7D","}");
            filter = filter.replace("%5B","[");
            filter = filter.replace("%5D","]");
            filter = filter.replace("%3A",":");
            this.homeFilters = JSON.parse(filter);
            this.filters.forEach(element => {
                this.filterSelected[element.name] = this.homeFilters[element.name];
            });
            this.filterHome(); // refresh les tags
        } else if(this.filters){
            this.filters.forEach(element => {
                this.filterSelected[element.name] = element.value
            });
        }
    },
    watch: {
        filters(val) {
            this.filterSelected = {}
            val.forEach(element => {
                this.filterSelected[element.name] = element.value
            });

            let homeFilters = {};
            let params = [];
            Object.keys(this.filterSelected).map(key => {
                let filter = this.filterSelected[key];
                if (typeof filter !== 'undefined' && (filter.length > 0 || Number.isFinite(filter) || (key === 'period' && filter.end))) {
                    homeFilters[key] = filter;
                }
            })

            if (Object.keys(homeFilters).length)
                params['homeFilters'] = JSON.stringify(homeFilters)

            if (Object.keys(params).length) {
                history.replaceState({}, null, this.$route.path + '?' + Object.keys(params)
                    .map(key => { return (encodeURIComponent(key) + '=' + encodeURIComponent(params[key])) })
                    .join('&'))
            } else {
                history.replaceState({}, null, this.$route.path);
            }
        }
    },
    methods: {
        saveAndFilter(){
            GenericDataService.postData('home/saveFilters', {filters: this.filterSelected}).then((response) =>{
                this.filterHome();
            })
        },
        filterHome(){
            this.dialogFilter = false
            this.$emit('filterHome', this.filterSelected)
        },
        removeFilter(filter, val){
            this.$emit('removeFilter', filter, val)
        }
    },
}
</script>
<style lang="scss">
    
</style>