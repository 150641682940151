<template>
   <div class="px-1 pt-1 pb-2 pt-0">
        <v-card rounded="lg">
            <v-card-title class="" style="border-bottom:1px solid #ddd">
                <h5 class="pb-0"><b>{{$t('sending_done')}}</b></h5>
            </v-card-title>
            <v-card-text>
                <v-row class="mt-2">
                    <v-col class="pa-2 d-flex align-content-stretch flex-column align-center justify-center" :cols="12/msgSent.length" v-for="(count, index) in msgSent" :key="index">
                        <v-img class="thumbImage" height="50" width="50" contain shaped :src="'/img/'+index+'.png'"></v-img>
                        <h5 class="my-2 mb-1" style="font-size:24px;"><b class="primary--text">{{ count }}</b></h5>
                        {{ $t('sent'+index) }}
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>        
    </div>
</template>
<script>
export default {
    name: "marketingMsgSent",
    props:["msgSent"],
    data() {
        return {
            
        }
    },
}
</script>
<style lang="scss">
    
</style>