<template>
    <v-card rounded="lg" class="pa-0 todoComponentDashboard">
        <v-card-title>
                <h5 class="pb-0"><b>{{$t('todos')}}</b></h5>
        </v-card-title>
        <v-card-text class="content d-flex flex-column justify-space-between cardText pa-0">
            
            <span v-if="todos.length > 0" class="px-4 py-0">
                <div v-for="todo in todos" :key="todo.id" @mouseover="testhover(todo.id, true)" @mouseout="testhover(todo.id, false)" :class="(hover[todo.id] ? 'active ': '')" class=" todoComp mb-2">
                    <TodoComponent :target="true" :link="true" :todo="todo" @getLink="getLink" :class="'px-2 '"/>
                </div>
                <v-btn color="pink" dark  @click="goToList" depressed class=" mb-4">
                    {{$t('SeeAll')}}
                </v-btn>
            </span>
            <v-alert class="mb-0 mx-6 mb-6" border="left" color="info" v-else text>
                {{ $t("noTodoLabel") }}
            </v-alert>
        </v-card-text>
    </v-card>
</template>
<script>
import GenericDataService from '@/services/GenericDataService'
import TodoComponent from '@/components/TodoComponent'
export default {
    name: "todosComponent",
    props:["todos"],
    components: {TodoComponent},
    data() {
        return {
            componentData: { },
            hover:{}
        }
    },
    methods: {
        testhover(id, value){
            this.$set(this.hover, id, value)
        },
        getLink($event) {
        },
        goToList() {
            this.$router.push({name: 'TodosList', query: {"datatableFilters": "{\"user_ids\":[\"" + this.$store.state.auth.user.id + "\"]}"}}); 
        }
    },
}
</script>
<style lang="scss">
    .todoComponentDashboard {
        height:auto;
        position:relative;
        overflow:hidden;
        .cardText {
            overflow-y:auto;
        }
        .content{
            height:100%;
            .todoComp {
                border:1px solid #EEE; 
                border-radius:5px;
                &.active {
                    background-color:#EEE;
                }
            }
        }
        h2 {
            min-height: 18px;
        }
        .header {
            display:flex;
            align-items:center;
        }
    }
</style>