<template>
  <div class="col col-12 pa-0 input-form">
    <span v-if="field.title" class="label"><b>{{ $t('monthFilterLabel') }}</b><span
        v-if="field.props && field.props.required" style="color:red">&nbsp;*</span></span>
    <v-menu v-model="modal" allow-overflow min-width="auto" min-height="auto" offset-y :close-on-content-click="false"
      style="flex-shrink:0; flex-grow:0;">
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on" @click="modal = true" style="width:48%;">
          <v-text-field solo dense validate-on-blur hide-details :rules="[validate]" ref="textareaDate" flat
            v-model="dateComputed" class="pt-0 mt-0" style="min-height:38px; flex-shrink:0; flex-grow:0;"
            :label="$t('dateLabel')">
            <template v-slot:prepend-inner>
              <v-icon small color="primary">
                $calendar_d
              </v-icon>
            </template>
          </v-text-field>
        </span>
      </template>
      <v-card class="width:auto; ">
        <v-card-text class="pa-0">
          <v-date-picker v-model="date" @input="okHandler" type="month" color="primary"></v-date-picker>
        </v-card-text>
      </v-card>
    </v-menu>
    <!-- <v-dialog ref="dialog" v-model="modal" width="340px">
        <template v-slot:activator="{ on }">
          <v-text-field
            :value="formattedShowingDate"
            :label="field.title ? $t(field.title) : ''"
            prepend-inner-icon="$calendar_r"
            readonly
            v-bind="field.props"
            v-on="on"
            :rules="[validate]"
          ></v-text-field>
        </template>
        <v-date-picker v-model="date" full-width :locale="lang" first-day-of-week="1">
          <v-spacer></v-spacer>
          <v-btn text color="grey lighten-1" @click.native="clearHandler">
            {{ $t('clear') }}
          </v-btn>
          <v-btn text color="green darken-1" @click="okHandler">
            {{ $t('ok') }}
          </v-btn>
        </v-date-picker>
      </v-dialog> -->
  </div>
</template>

<script>
import validate from '../functions/validations'
import { format, parse } from 'date-fns'
import dateManipulation from '@/mixins/dateManipulation'
import infoTooltip from '@/components/infoTooltip';

const DEFAULT_DATE_FORMAT = 'yyyy-MM';
const DEFAULT_SHOWING_FORMAT = 'MM/yyyy';
const DEFAULT_RETURN_FORMAT = 'yyyy-MM';

export default {
  props: ["model", "fieldName", "field"],
  components: { infoTooltip },
  mixins: [validate, dateManipulation],
  data() {
    return {
      modal: false,
      date: '',
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD', // Uses 'iso' if missing
      },
    };
  },
  created() {
  },
  mounted() {
    this.init();
  },
  watch: {
    model: function () {
      this.init()
    }
  },
  methods: {
    init() {
      if (!this.model[this.fieldName] || this.model[this.fieldName] == "") {
        this.date = "";
        return;
      }

      let initDate;

      if (this.model[this.fieldName] instanceof Date) {
        initDate = this.model[this.fieldName];
      } else if (typeof this.model[this.fieldName] === 'string' || this.model[this.fieldName] instanceof String) {
        //initDate = parse(this.model[this.fieldName], this.dateReturnFormat, new Date())
        initDate = new Date(this.model[this.fieldName])
      }

      this.date = format(initDate, DEFAULT_DATE_FORMAT);
    },
    okHandler() {
      this.modal = false;
      this.model[this.fieldName] = this.formattedReturnDate;
    },
    clearHandler() {
      this.modal = false;
      this.date = '';
      this.model[this.fieldName] = null;
    },
  },
  computed: {
    dateComputed() {
      let date = ''
      if (this.date != '') {
        date = this.getMonthYear(this.date)
      }
      return date
    },
    lang() {
      if (this.$store.state.auth.currentUser.parameters.lang) {
        return this.$store.state.auth.currentUser.parameters.lang
      } else {
        return this.$store.state.auth.currentUser.profile.lang
      }
    },
    dateShowingFormat() {
      return this.field.props.showingFormat ? this.field.props.showingFormat : DEFAULT_SHOWING_FORMAT
    },
    dateReturnFormat() {
      return this.field.props.returnFormat ? this.field.props.returnFormat : DEFAULT_RETURN_FORMAT
    },
    formattedShowingDate() {
      return this.selectedDate ? format(this.selectedDate, this.dateShowingFormat) : ''
    },
    formattedReturnDate() {
      return this.selectedDate ? format(this.selectedDate, this.dateReturnFormat) : ''
    },
    selectedDate() {
      if (this.date)
        return parse(this.date, DEFAULT_DATE_FORMAT, new Date());

      return null;
    },
  }
};
</script>

<style lang="scss">
.v-application--is-ltr .v-text-field .v-input__prepend-inner {
  padding-right: 15px;
}
</style>