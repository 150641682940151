<template>
  <div class="home" style="overflow: auto; height: inherit">
    <v-container fluid>
      <v-row>
        <v-col>
          <h1>FRONT CHEATSHEET</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          Couleurs déclarées :
          <div class="primary--text">primary</div>
          <div class="error--text">error</div>
          <div class="success--text">success</div>
          <div class="info--text">info</div>
          <div class="warning--text">warning</div>
          <div class="black bg--text">bg</div>
          <div class="black lightgrey--text">lightgrey</div>
          <div class="darkgrey--text">darkgrey</div>
        </v-col>
        <v-col cols="3">
          <h1>Titre H1</h1>
          <h2>Titre H2</h2>
          <h2 class="icon-title">
            Titre avec icone en fin de ligne
            <v-btn
                    small
                    depressed
                    color="primary"
                    class="square"
            >
              <v-icon dark small> $plus_l </v-icon>
            </v-btn>
          </h2>
          <h3>Titre H3</h3>
          <h4>Titre H4</h4>
          <p>Paragraphe</p>
        </v-col>
        <v-col cols="3">
          <div>
            <h2>Chips</h2>
            <v-chip color="primary" small outlined
              >Chips (small, outlined)</v-chip
            >&nbsp;
            <v-chip color="primary" small outlined>
              <v-icon left small>$user_tie_l</v-icon>
              Chips avec prepend icon
            </v-chip>
            <v-chip color="primary" small outlined>
              Chips avec append icon
              <v-icon right small>$info_s</v-icon>
            </v-chip>
          </div>
          <div class="mt-2">
            <h2>Boutons</h2>

            <p>
              <v-btn dense depressed color="primary" class="mt-1">
                <v-icon left dark small> $alarm_clock_l </v-icon>
                Rappel
              </v-btn>
            </p>

            <p>

            <p>
              <v-btn small depressed color="primary">
                Rappel (button small depressed)
              </v-btn>
            </p>

            <p>
            <v-btn small depressed color="primary" class="mt-1">
              <v-icon left dark small> $alarm_clock_l </v-icon>
              Rappel (avec icone en small)
            </v-btn>
            </p>

            <p>
            <v-btn
                    depressed
                    color="primary"
                    class="square"
            >
              <v-icon dark small> $alarm_clock_l </v-icon>
            </v-btn>
            : utiliser la classe square sur le v-btn
          </p>

            <p>
              <v-btn
                      small
                      depressed
                      color="primary"
                      class="square mr-1"
              >
                <v-icon dark small> $alarm_clock_l </v-icon>
              </v-btn>

              <v-btn
                      small
                      depressed
                      color="primary"
                      class="square"
              >
                <v-icon dark small> $alarm_clock_l </v-icon>
              </v-btn>
              : avec l'attribut small (mr-1 espace entre 2 icons primary)
            </p>

            <p>
              <v-btn depressed text class="square">
              <v-icon dark small> $alarm_clock_l </v-icon>
            </v-btn>
              <v-btn depressed text class="square">
                <v-icon dark small> $trash_l </v-icon>
              </v-btn>
              : sans color primary (pas d'espaces entre)
            </p>
          </div>
        </v-col>
        <v-col cols="3">
          <h2>Classes utilitaires</h2>
          <p>
            <span class="primary--text">primary--text</span> : colorise le texte
            avec la couleur primary (on peut remplacer le primary par toute les
            couleurs déclarées)
          </p>
          <p>
            <span class="primary bg--text">primary</span> : colorise le
            background
          </p>
          <p>
            Pour utiliser les couleurs vuetify dans le CSS d'un composant,
            l'appeler comme suit : color: var(--v-darkgrey-base) !important;
          </p>
          <p>
            Marges : pour les marges entre blocs on part sur une valeur de 3
            (mt-3, mb-3, ml-3, mr-3, ma-3)
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <h2>Icones Déclarées :</h2>
          <div class="icons">
            <div class="icon" v-for="(icon, index) in icons" :key="index">
              <v-icon>${{ index }}</v-icon>
              {{ index }}
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Organization from "../components/Organization.vue";

export default {
  name: "Home",
  components: {
    Organization,
  },
  data() {
    return {
      orgId: 1,
      iconsLight: [
        "map",
        "phone",
        "filter",
        "loupe",
        "unfold",
        "next",
        "prev",
        "checkboxOn",
        "checkboxOff",
        "checkboxIndeterminate",
        "menu",
        "comment",
        "arobase",
        "envelope",
        "close",
        "clear",
        "info",
        "pen",
        "unlock",
      ],
      iconsRegular: ["emptystar", "calendar", "clock", "plus"],
      iconsSolid: [
        "fullstar",
        "halfstar",
        "square",
        "dropdown",
        "delete",
        "check",
      ],
      iconsDuo: [
        "pencil",
        "settings",
        "return",
        "dashboard",
        "operations",
        "leads",
        "campagne",
        "agenda",
        "contacts",
        "actions",
        "ventes",
        "utilisateurs",
        "user",
        "catalogue",
        "templates",
        "trash",
        "see",
      ],


    };
  },
  computed: {
    icons: function (e) {
      return this.$vuetify.icons.values;
    },
  },
  methods: {},
  created() {
  },
  watch: {},
};
</script>
<style lang="scss">
.organizations {
  max-width: 250px;
}

.container {
  height: auto;
  overflow: auto;
}

.home {
    .icons {
      display: flex;
      flex-wrap: wrap;
      max-width: 80%;
      .icon {
        margin-bottom: 10px;
        margin-right: 30px;
        padding: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        //border: 1px solid #aaa;
      }
    }
}


</style>
