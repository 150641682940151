<template>
    <div class="px-1 pt-1 pb-2 pt-0" style="height:100%;">
         <v-card rounded="lg" height="100%">
             <v-card-title class="align-center">
                 <h5 class="pb-0 w-100 text-center primary--text" style="width:100%;"><b>{{$t(data.label)}}</b></h5>
             </v-card-title>
             <v-card-text>
                 <v-row class="mt-1 mb-1" v-for="(data, index) in data.data" :key="index">
                     <v-col class="pa-2 d-flex align-content-stretch align-center justify-center" >
                        <div style="width:40%" class="d-flex align-center text-center justify-end">
                            <v-icon color="primary" class="mr-6">{{ data.icon }}</v-icon>
                        </div>
                        <div class="d-flex align-center"  style="width:59%">
                            <div>
                                <div class="d-flex align-end">
                                    <h2 class="mb-0 mr-2" style="min-height:unset; font-size:24px; line-height:unset;">{{ data.value }}{{ ['invested', 'costpersubmissions'].includes(data.label) ? '€' : '' }}</h2>
                                    <span style="line-height:16px;" :class="data.color+'--text'">{{ data.percent+(data.percent != '-' ? '%' : '') }}</span>
                                </div>
                                <div class="mt-1 black--text">{{ $t(data.label) }}</div>
                            </div>
                        </div>
                     </v-col>
                 </v-row>
                 <v-row  v-if="data.link">
                    <v-col class="text-center">
                        <a @click="$emit(data.link.event, $event)">
                            {{ $t(data.link.label) }}
                        </a>
                    </v-col>
                 </v-row>
             </v-card-text>
         </v-card>        
     </div>
 </template>
 <script>
 export default {
     name: "marketingStatPack",
     props:["data"],
     data() {
         return {
             
         }
     },
 }
 </script>
 <style lang="scss">
     
 </style>