<template>
    <div class="px-1 pb-3 pt-0">
        <v-card rounded="lg">
            <v-card-title class="" style="border-bottom:1px solid #ddd">
                <h5 class="pb-0"><b>{{$t('opportunities')}}</b></h5>
            </v-card-title>
            <v-card-text>
                <v-row class="mt-2">
                    <v-col class="pa-2 d-flex align-content-stretch" cols="4" v-for="(opportunity, index) in alerts" :key="opportunity.tag">
                        <v-card class="w-100 rounded-lg" @click="goTo(opportunity.tag)" style="width:100%">
                            <v-card-text class="d-flex align-content-stretch">
                                <div>
                                    <v-img :height="60" :width="60" :src="opportunity.picto.url"></v-img>
                                </div>
                                <div class="ml-3">
                                    <h1 class="blacked autoheight">{{ opportunity.n }}</h1>
                                    <p class="mb-0 black--text desctag">{{ $t(opportunity.tag) }}</p>
                                </div>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon small class="icontooltip" v-bind="attrs" v-on="on"> 
                                            $circleinfo_d
                                        </v-icon>
                                    </template>
                                    <span>{{$t(opportunity.picto.desc)}}</span>
                                </v-tooltip>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>        
    </div>
</template>
<script>
    import GenericDataService from '@/services/GenericDataService'
    export default {
        name: "alertHomeComponent",
        props:['alerts'],
        data() {
            return {
                opportunities : []
            }
        },
        methods: {
            goTo(tag){
                this.$router.push('/contacts/?datatableFilters=%7B"alert"%3A%5B"'+tag+'"%5D%7D');
            }
        },
    }
</script>
<style lang="scss">
    .desctag {
        font-size:120%;
    }
    .autoheight {
        height: auto !important;
    }
    .icontooltip {
        position:absolute !important;
        top:10px;
        right:10px;
    }
</style>