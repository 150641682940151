<template>
   <div class="px-1 pb-1 pt-0">
        <div class="d-flex flex-wrap justify-space-between">
                <div v-for="(stat, index) in stats" :class="'statMarket px-1 '+(index == 0 ? 'pl-0' : '')+((index + 1) == stats.length ? 'pr-0' : '')" :key="index+'stat'">
                    <v-card style="height:100%" rounded="lg">
                        <v-card-text>
                            <div class="d-flex flex-column align-center justify-center">
                                <v-list-item-avatar  :color="stat.icon_color">
                                    <v-icon class="iconTodo" small color="white">
                                    {{ stat.icon }}
                                    </v-icon>
                                </v-list-item-avatar>
                                <h5 class="my-3" style="font-size:28px;"><b class="primary--text">{{ stat.count }}</b></h5>
                                <span class="text-center">{{ $t(stat.label) }}</span>
                            </div>
                        </v-card-text>
                    </v-card>    
                </div>
                
        </div>
    </div>
</template>
<script>
export default {
    name: "marketingStats",
    props:["stats"],
    data() {
        return {
            
        }
    },
}
</script>
<style lang="scss">
    .statMarket {
        width:0;
        flex-grow:1;
        flex-shrink:0;
    }
</style>