<template>
    <div class="errorPage">
        <h1 class="mb-8">La page que vous voulez afficher n'existe pas.</h1>
        <v-btn depressed color="primary" :to="{name:'Home'}">Retour a l'accueil</v-btn>
    </div>
</template>
<script>
export default {
    name:"Error404"
}
</script>
<style lang="scss">
    .errorPage {
        height:100%;
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
</style>